<template>
  <div>
    <b-row>
      <b-col md="12">
        <a
          @click="$router.back()"
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom gutter-b" v-if="currentUjian">
          <div class="card-body">
            <div class="d-flex mb-9">
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex flex-column mr-3">
                    <a
                      href="#"
                      class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                    >
                      {{ currentUjian.name }}
                    </a>
                    <a
                      href="#"
                      class="text-dark-50 text-hover-primary font-size-h7 font-weight-bold mr-3"
                    >
                      <!-- {{ currentUjian.description }} -->
                    </a>
                  </div>

                  <div class="my-lg-0 my-3">
                    <button
                      v-b-toggle.deskripsi
                      class="btn btn-light-info font-weight-bolder font-size-sm mr-2"
                    >
                      Lihat Deskripsi
                    </button>
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a
                        href="#"
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >
                        <i class="flaticon-clock-1 mr-2 font-size-lg"></i>
                        {{ currentUjian.time }} Menit
                      </a>
                      <a
                        href="#"
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        ><i class="flaticon2-calendar-2 mr-2 font-size-lg"></i>
                        {{
                          currentUjian.opening_time | moment("dddd, LL HH:mm")
                        }}
                        -
                        {{
                          currentUjian.closing_time | moment("dddd, LL HH:mm")
                        }}
                      </a>
                    </div>

                    <b-collapse id="deskripsi" class="mt-2">
                      <b-card>
                        <div v-html="currentUjian.description"></div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->

            <div class="separator separator-solid"></div>

            <!--begin::Items-->
            <div class="d-flex align-items-center flex-wrap mt-8">
              <!--begin::Item-->
              <div class="d-flex align-items-center mr-5 mb-2">
                <span class="mr-4">
                  <i
                    class="flaticon-file-2 display-4 text-muted font-weight-bold"
                  ></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm"
                    >Jumlah Soal</span
                  >
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">
                      {{ currentUjian.question_pack_id.questions.length }}
                    </span>
                  </span>
                </div>
              </div>
              <!--end::Item-->

              <!--begin::Item-->
              <div class="d-flex align-items-center mr-5 mb-2">
                <span class="mr-4">
                  <i
                    class="flaticon-users display-4 text-muted font-weight-bold"
                  ></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm"
                    >Jumlah Pegawai</span
                  >
                  <span class="font-weight-bolder font-size-h5">
                    {{ totalRows }}
                    <!--                    <span class="text-dark-50 font-weight-bold">$</span>-->
                    <!-- {{ getTotalStudents }} -->
                  </span>
                </div>
              </div>
              <!--end::Item-->

              <!--begin::Item-->
              <!--end::Item-->

              <!--begin::Item-->

              <!--end::Item-->
            </div>
            <!--begin::Items-->
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="card card-custom">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Daftar Nilai Pegawai
          </span>
        </h3>
        <div class="card-toolbar">
          <!-- <span
            @click="nilaiAkhir"
            class="btn btn-success font-weight-bolder font-size-sm mr-2"
          >
            Hitung Nilai Akhir</span
          > -->
        </div>
      </div>
      <div class="card-body pt-4">
        <b-row class="justify-content-between">
          <b-col cols="auto">
            <b-form-group>
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label="Filter"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
          :filter="filter"
          :current-page="currentPage"
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :busy="isLoading"
          @filtered="onFiltered"
          show-empty
          responsive
        >
          <template #empty="scope">
            <h6 class="text-center">Tidak ada soal ditemukan</h6>
          </template>
          <template #emptyfiltered="scope">
            <h6 class="text-center">Tidak ada soal ditemukan</h6>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="row">
            {{ perPage * (currentPage - 1) + (row.index + 1) }}
          </template>
          <template #cell(siswa)="data">
            <b-row class="align-items-center">
              <b-col cols="auto">
                <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                  <img
                    :src="`https://eschool.smkn4bdg.sch.id/img.php?src=http://img.smkn4bdg.sch.id/siswa/${data.item.student_id.no_induk}.jpg`"
                    class="symbol-label"
                    alt=""
                  />
                </div>
              </b-col>
              <b-col>
                <a
                  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ data.item.student_id.full_name }}</a
                >
                <span class="text-muted font-weight-bold d-block">{{
                  data.item.student_id.no_induk
                }}</span>
              </b-col>
            </b-row>
          </template>
          <template #row-details="row">
            <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
              Soal:
            </div>
            <div v-html="row.item.soal"></div>
          </template>
          <template #cell(progress)="data">
            <div class="d-flex align-items-center">
              <div class="progress progress-xs mx-3 w-100">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  :style="`width: ${getProgress(data.item)}%;`"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span class="font-weight-bolder text-dark"
                >{{ getProgress(data.item) }}%</span
              >
            </div>
            <div
              class="d-flex align-items-center"
              v-if="getProgress(data.item) != 100"
            >
              <div
                class="badge w-100"
                :class="{
                  'badge-info': getProgress(data.item) > 0,
                  'badge-danger':
                    evaluatedStatus(data.item) == 'belum di evaluasi',
                  'badge-warning text-white':
                    evaluatedStatus(data.item) == 'pegawai belum mengerjakan',
                }"
              >
                {{ evaluatedStatus(data.item) }}
              </div>
            </div>
          </template>
          <template #cell(scores)="data">
            <span
              class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
            >
              {{ data.item.scores }} pts
            </span>
          </template>
          <template #cell(action)="data">
            <router-link
              :to="`/ujian/${idUjian}/penilaian/${data.item.student_id._id}`"
              class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
              >Penilaian</router-link
            >
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          class="my-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_FROM_BANK_SOAL } from "@/core/services/store/soal.module";
import { GET_BANK_SOAL } from "@/core/services/store/banksoal.module";
import moment from "vue-moment";

import {
  CLEAR_UJIAN,
  GET_SOAL_ESSAY,
  GET_LIST_RESULT_EXAM,
  NILAI_AKHIR,
  GET_ONE_UJIAN,
} from "@/core/services/store/ujian.module";
// import loading from "vue-loading-overlay"
export default {
  name: "ListSoalEssay",
  components: {
    // loading
  },
  data() {
    return {
      isLoading: false,
      modalJawaban: false,
      selectedSoal: {},
      currentPage: 1,
      totalRows: 0,
      tabIndex: 0,
      items: [],
      perPage: 10,
      modalBankSoal: false,
      bankSoal: [],
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "Siswa",
          label: "Pegawai",
          sortable: true,
        },

        {
          key: "scores",
          label: "Nilai",
          sortable: true,
        },
        {
          key: "progress",
          label: "Progress",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        truefalse: "True/False",
        essay: "Essay",
      },
      currentSoal: {},
      currentPageBank: 1,
      totalRowsBank: 0,
      perPageBank: 10,
      filterBank: null,
      selectAll: false,
      fieldsBank: [
        {
          key: "select",
          sortable: false,
        },
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_soal",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "bobot",
          label: "Bobot Soal",
          sortable: true,
        },
        {
          key: "tipe",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      currentUjian: null,
    };
  },
  watch: {
    tabIndex() {
      // const currentClassId = this.currentUjian.class_id[this.tabIndex]._id;
      // this.items = this.currentUjianResult.filter((item) =>
      //   item.student_id.class_id.includes(currentClassId)
      // );
      // this.totalRows = this.items.length;
    },
  },
  mounted() {
    this.$store.dispatch(CLEAR_UJIAN);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Penilaian soal" }]);
    Promise.all([this.getData(), this.getDetail()]);
    // this.getBankSoal();
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters(["currentUjianResult"]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    getTotalStudents() {
      var total = 0;
      this.currentUjian.kurikulum.one_ajar.jadwal.forEach((item) => {
        total += item.kelas.det_kelas.length;
      });
      return total;
    },
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    getProgress(item) {
      const answered = item.answers
        .filter((item) => item.type == "essay")
        .filter(
          (point) =>
            point.has_been_evaluated !== undefined &&
            point.has_been_evaluated == true
        ).length;

      const questions = item.answers.filter(
        (item) => item.type == "essay"
      ).length;

      const result = ((questions / answered) * 100).toFixed(0);

      if (answered == 0) return 0;
      return isNaN(result) ? 0 : result;
    },
    evaluatedStatus(item) {
      const employeeAnswer = item.answers.filter(
        (item) => item.type == "essay"
      );

      const answered = employeeAnswer.filter(
        (point) =>
          point.has_been_evaluated !== undefined ||
          point.has_been_evaluated == true
      ).length;

      if (employeeAnswer.length == 0) return "pegawai belum mengerjakan";
      if (answered == 0) return "belum di evaluasi";
    },
    getFromBankSoal() {
      this.$bvModal
        .msgBoxConfirm(
          "Apakah anda yakin ingin ambil data soal dari bank soal?",
          {
            title: "Konfirmasi",
            size: "sm",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "Ya",
            cancelTitle: "Tidak",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.isLoading = true;
            var _data = this.bankSoal
              .filter((item) => {
                return item.checked;
              })
              .map((item) => {
                return item.id;
              });
            this.$store
              .dispatch(GET_FROM_BANK_SOAL, { id: this.idUjian, ids: _data })
              .then((response) => {
                this.getData();

                this.$bvToast.toast("Berhasil ambil dari bank soal", {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Export Rekap Nilai`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        });
    },
    onChangeAll() {
      this.bankSoal.forEach((item, index) => {
        this.$set(this.bankSoal[index], "checked", !this.selectAll);
      });
    },
    onChangeRow(e) {
      if (!e.target.checked) {
        this.selectAll = false;
      } else {
        var allSelected = this.bankSoal.every((val) => {
          return val.checked;
        });
        if (allSelected) {
          this.selectAll = true;
        }
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredBank(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBank = filteredItems.length;
      this.currentPageBank = 1;
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_LIST_RESULT_EXAM, this.idUjian)
        .then(() => {
          this.isLoading = false;
          this.items = this.currentUjianResult;
          this.totalRows = this.items.length;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat SOal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getDetail() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_UJIAN, this.idUjian)
        .then((response) => {
          this.currentUjian = response.data;

          const tanggal_dibuka = moment(this.currentUjian.opening_time).format(
            "YYYY-MM-DD"
          );
          const tanggal_ditutup = moment(this.currentUjian.closing_time).format(
            "YYYY-MM-DD"
          );

          const start_time = moment(this.currentUjian.opening_time).format(
            "HH:mm"
          );

          const end_time = moment(this.currentUjian.closing_time).format(
            "HH:mm"
          );

          this.currentUjian.tanggal_dibuka = tanggal_dibuka;
          this.currentUjian.tanggal_ditutup = tanggal_ditutup;
          this.currentUjian.tanggal_dibuka_jam = start_time;
          this.currentUjian.tanggal_ditutup_jam = end_time;

          // const data = this.currentClassList.data.filter((item) =>
          //   this.currentUjian.class_id.includes(item._id)
          // );

          // this.currentAssignClass = data;

          this.selectedQuestionPack = this.currentUjian.question_pack_id._id;

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    nilaiAkhir() {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(NILAI_AKHIR, { id_ujian: this.idUjian })
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hitung nilai akhir`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Hitung Nilai Akhir`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    viewJawaban(item) {
      this.selectedSoal = item;
      this.modalJawaban = true;
    },
    getBankSoal() {
      const param = new URLSearchParams();
      param.append("id_kurikulum", this.idKurikulum);
      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then((response) => {
          this.bankSoal = response.data;
          this.totalRowsBank = this.bankSoal.length;
          // this.isLoading = false
        })
        .catch(() => {
          // this.isLoading = false
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style scoped></style>
